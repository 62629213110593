<template>
    <div>
        <VTable :data="payments"
                :filter="filter"
                :filter-fields="['customer.identifier', 'customer.name', 'sales_order_number', 'invoice_number']"
                :initial-sort="{ field: 'created', order: false }"
                :page-size="20">
            <template #header>
                <tr>
                    <th>Date</th>
                    <th>Customer</th>
                    <th>Sales Order</th>
                    <th>Invoice</th>
                    <th>Amount</th>
                    <th colspan="2">Account</th>
                    <th>Notes</th>
                    <th class="pr-3">Applied in SAP</th>
                    <th v-if="$auth.hasSomePermission('refund:manual_payments')" />
                </tr>
            </template>
            <template #row="{ item: payment }">
                <tr :key="payment.id" class="btn-reveal-trigger">
                    <td class="py-2 white-space-nowrap">
                        {{ payment.created | formatISODate('Pp') }}
                    </td>
                    <td class="py-2 white-space-nowrap">
                        <span class="badge badge-soft-secondary align-text-bottom">{{ payment.customer.identifier }}</span>
                        {{ payment.customer.name }}
                    </td>
                    <td class="py-2 white-space-nowrap">
                        {{ payment.sales_order_number }}
                    </td>
                    <td class="py-2 white-space-nowrap">
                        {{ payment.invoice_number }}
                    </td>
                    <td class="py-2 white-space-nowrap">
                        <template v-if="!payment.is_refunded">{{ payment.amount | formatCents }}</template>
                        <del v-else title="Payment was refunded">{{ payment.amount | formatCents }}</del>
                    </td>
                    <td class="py-2 white-space-nowrap">
                        <CCBrandIcon v-if="payment.credit_card_type" :card-brand="payment.credit_card_type" />
                        <i v-else-if="payment.account_type" class="fas fa-university" />
                    </td>
                    <td class="py-2 white-space-nowrap">
                        <span v-if="payment.credit_card_type">{{ payment.credit_card_last_4 }}</span>
                        <span v-else-if="payment.account_type">{{ payment.account_last_4 }}</span>
                    </td>
                    <td class="py-2" style="max-width: 0;">
                        <div v-if="payment.notes && payment.notes.length > 0"
                             @click="inspectingPaymentNotes = payment"
                             class="text-truncate"
                             style="cursor: pointer;"
                             role="button">
                            <i class="fa fa-plus-circle opacity-75" /> {{ payment.notes }}
                        </div>
                        <span v-else class="font-italic text-500">None</span>
                    </td>
                    <td class="py-2 align-middle text-center white-space-nowrap">
                        <div class="custom-control custom-switch">
                            <input type="checkbox"
                                   v-model="payment.is_updated_in_erp"
                                   @change="toggleErpStatus(payment)"
                                   :disabled="payment._loading || !$auth.hasPermissions('update:manual_payments')"
                                   :id="`erp_status_${payment.id}`"
                                   class="custom-control-input"
                                   aria-label="Transferred to ERP System">
                            <label class="custom-control-label" :for="`erp_status_${payment.id}`" />
                        </div>
                    </td>
                    <td class="py-2 align-middle white-space-nowrap">
                        <div class="dropdown text-sans-serif">
                            <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal mr-3"
                                    :disabled="payment.is_refunded"
                                    type="button"
                                    data-toggle="dropdown">
                                <span class="fas fa-ellipsis-h fs--1" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right border py-0">
                                <div class="bg-white py-2">
                                    <button class="dropdown-item text-danger"
                                            @click="refundingPayment = payment"
                                            v-if="$auth.hasPermissions('refund:manual_payments') && !payment.is_refunded">
                                        Refund Payment...
                                    </button>
                                    <button class="dropdown-item"
                                            @click="generateReceipt(payment)"
                                            :disabled="receiptLoading">
                                        Download Receipt
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </VTable>
        <Modal :shown="inspectingPaymentNotes != null" @close="inspectingPaymentNotes = null" centered>
            <template #default>
                <div class="modal-header border-bottom-0 pb-0">
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span class="font-weight-light" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div style="white-space: pre-wrap;">{{ inspectingPaymentNotes.notes.trim() }}</div>
                </div>
            </template>
        </Modal>
        <Modal :shown="refundingPayment != null"
               @close="refundingPayment = null"
               centered
               :non-dismissible="refundLoading">
            <template #default>
                <div class="modal-header">
                    <h5 class="modal-title">Refund Payment</h5>
                </div>
                <div class="modal-body">
                    <p>Are you sure that you want to refund the following payment?</p>
                    <table class="table" style="table-layout: fixed;">
                        <tr>
                            <th class="py-2 border-top-0">Customer</th>
                            <td class="py-2 border-top-0">
                                <span class="badge badge-soft-secondary align-text-bottom">
                                    {{ refundingPayment.customer.identifier }}
                                </span>
                                {{ refundingPayment.customer.name }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-2">Amount</th>
                            <td class="py-2">{{ refundingPayment.amount | formatCents }}</td>
                        </tr>
                        <tr>
                            <th class="py-2">Sales Order Number</th>
                            <td v-if="refundingPayment.sales_order_number" class="py-2">
                                {{ refundingPayment.sales_order_number }}
                            </td>
                            <td v-else class="py-2 text-500">N/A</td>
                        </tr>
                        <tr>
                            <th class="py-2">Invoice Number</th>
                            <td v-if="refundingPayment.invoice_number" class="py-2">
                                {{ refundingPayment.invoice_number }}
                            </td>
                            <td v-else class="py-2 text-500">N/A</td>
                        </tr>
                        <tr>
                            <th class="py-2">Notes</th>
                            <td v-if="refundingPayment.notes" class="py-2 text-truncate">
                                {{ refundingPayment.notes }}
                            </td>
                            <td v-else class="py-2 text-500">N/A</td>
                        </tr>
                    </table>
                    <div class="alert alert-danger mb-0">
                        <i class="fa fa-exclamation-circle mr-2" />You cannot undo this action!
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="refundLoading"
                            class="btn btn-secondary btn-sm"
                            type="button"
                            @click="refundingPayment = null">
                        Cancel
                    </button>
                    <button :disabled="refundLoading"
                            class="btn btn-danger btn-sm"
                            type="button"
                            @click="refundPayment(refundingPayment)">
                        <span v-if="refundLoading"
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true" />
                        Refund
                    </button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { refundManualPayment, updateManualPayment } from '@/services/ManualPaymentService';
    import CCBrandIcon from '@/components/CCBrandIcon.vue';
    import { downloadFile } from '@/util';
    import { formatISODate } from '@/filters';
    import Modal from '@/components/Modal';
    import VTable from '@/components/VTable';

    export default {
        name: 'PaymentsTable',
        components: { VTable, Modal, CCBrandIcon },
        props: {
            payments: { type: Array, default: () => [] },
            filter: { type: String, default: '' },
        },
        data() {
            return {
                inspectingPaymentNotes: null,
                refundingPayment: null,
                refundLoading: false,
                receiptLoading: false,
            };
        },
        methods: {
            async toggleErpStatus(payment) {
                this.$set(payment, '_loading', true);
                try {
                    await updateManualPayment(payment.id, { is_updated_in_erp: payment.is_updated_in_erp });
                } catch {
                    payment.is_updated_in_erp = !payment.is_updated_in_erp; // Revert the value

                    this.$alerts.danger('An error occurred', 'Unable to update payment status.');
                } finally {
                    payment._loading = false;
                }
            },
            async refundPayment(payment) {
                this.refundLoading = true;
                try {
                    await refundManualPayment(payment.id);
                    payment.is_refunded = true;
                } catch (error) {
                    this.$alerts.danger('Unable to refund payment', error.response?.data?.detail);
                } finally {
                    this.refundLoading = false;
                    this.refundingPayment = null;
                }
            },
            async generateReceipt(payment) {
                this.receiptLoading = true;

                try {
                    await downloadFile(`/manual_payments/${payment.id}/receipt/`,
                                       `${formatISODate(payment.created, 'PP')} Payment Receipt.pdf`);
                } catch {
                    this.$alerts.danger('Unable to generate payment receipt',
                                        'Please contact support if this problem persists.');
                } finally {
                    this.receiptLoading = false;
                }
            },
        },
    };
</script>
