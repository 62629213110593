<template>
    <!-- Keyed wrapper ensures the element is destroyed and re-created properly for FontAwesome -->
    <span :key="faClass">
        <i :class="[faClass === 'credit-card' ? 'far' : 'fab', `fa-${faClass}`]" />
    </span>
</template>

<script>
    export default {
        name: 'CCBrandIcon',
        props: {
            cardBrand: {
                type: String,
                required: true,
            },
        },
        computed: {
            faClass() {
                switch (this.cardBrand) { // Braintree 'card_type' strings
                case 'American Express':
                    return 'cc-amex';
                case 'Discover':
                    return 'cc-discover';
                case 'JCB':
                    return 'cc-jcb';
                case 'MasterCard':
                    return 'cc-mastercard';
                case 'Visa':
                    return 'cc-visa';
                // Cards without a FontAwesome icon
                case 'Carte Blanche':
                case 'China UnionPay':
                case 'Elo':
                case 'Laser':
                case 'Maestro':
                case 'Solo':
                case 'Switch':
                case 'Unknown':
                default:
                    return 'credit-card';
                }
            },
        },
    };
</script>
